import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import moment from "moment"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"

const DealerHours: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const [currentNow, setCurrentNow] = React.useState(moment.now())
  const { _ } = useContext(LanguageContext)
  const [hoursToDisplay, setHoursToDisplay] = React.useState(true)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  dealer?.Departments?.map(department => {
    department.hoursToDisplay = []
    department.hoursOfOperation.forEach((item, index, arr) => {
      if (index === 0) {
        department.hoursToDisplay.push({
          day: item.DayOfWeek,
          openTime: item.OpeningTime,
          closeTime: item.ClosingTime,
        })
      }
      if (
        item.OpeningTime !==
          department.hoursToDisplay[department.hoursToDisplay.length - 1]
            .openTime ||
        item.ClosingTime !==
          department.hoursToDisplay[department.hoursToDisplay.length - 1]
            .closeTime
      ) {
        // this code says if we have now encountered a day with a different open/close time, then we need to add the previous day as the second
        //day in the Monday - Friday display as an example. THe first if check is to make sure it doesn't do Saturday - Saturday when two days back to back have different times
        if (
          department.hoursToDisplay[department.hoursToDisplay.length - 1]
            .day !== arr[index - 1].DayOfWeek
        ) {
          department.hoursToDisplay[department.hoursToDisplay.length - 1].day =
            department.hoursToDisplay[department.hoursToDisplay.length - 1]
              .day +
            " - " +
            arr[index - 1].DayOfWeek
        }

        department.hoursToDisplay.push({
          day: item.DayOfWeek,
          openTime: item.OpeningTime,
          closeTime: item.ClosingTime,
        })
      }
    })
  })

  // add logic to account for rare instance that all hours are missing from the dealer's data
  useEffect(() => {
    let dept = []
    dealer?.Departments?.map((department: any) => {
      if (department.hoursOfOperation.length !== 0) {
        dept.push(department.hoursOfOperation)
      }
    })
    if (dept.length == 0) {
      setHoursToDisplay(false)
    }
  }, [dealer?.Departments])

  let isOpenNow = false
  const todaysHours =
    dealer?.Departments?.length > 0
      ? dealer?.Departments[0].hoursOfOperation.filter(
          (item: { DayOfWeek: string }) =>
            item.DayOfWeek === moment(currentNow).format("dddd"),
        )
      : null

  if (todaysHours && todaysHours[0] && todaysHours[0].OpeningTime) {
    isOpenNow = isOpen(
      todaysHours[0].OpeningTime,
      todaysHours[0].ClosingTime,
      dealer.State === "AL" ? "America/Chicago" : "America/New_York",
    )
  }

  function isOpen(openTime: string, closeTime: string, timezone: string) {
    //const now = moment.now()
    const date = moment(currentNow).format("YYYY-MM-DD")
    const storeOpenTime = moment(
      date + " " + openTime,
      "YYYY-MM-DD h:mmA",
      timezone,
    )
    const storeCloseTime = moment(
      date + " " + closeTime,
      "YYYY-MM-DD h:mmA",
      timezone,
    )

    return moment(currentNow).isBetween(
      storeOpenTime,
      storeCloseTime,
      null,
      "[)",
    )
  }

  // Parameters:  Name of event and an object with additional properties for tealium event call if required
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {},
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      ...moreData,
    })
  }

  return (
    <div css={[tw`bg-gray-100 rounded-lg m-0 mt-4 p-8 relative md:(m-4)`]}>
      <h2 css={[tw`text-2xl tracking-widest mb-4 font-light`]}>{_("Hours")}</h2>
      <div css={[tw`absolute right-4 top-4`]}>
        {isOpenNow && (
          <span
            css={[
              tw`h-20 w-20 bg-white rounded-full flex items-center justify-center text-center text-red-400 font-semibold leading-tight uppercase shadow-5`,
            ]}
          >
            {_("Open Now")}
          </span>
        )}
      </div>
      {hoursToDisplay ? (
        dealer?.Departments?.map(department => {
          return department.hoursToDisplay.length === 0 ? null : ( // dont show depts that dont have hours
            <div
              css={tw`grid overflow-hidden grid-cols-12 grid-rows-1 gap-1 grid-flow-row w-auto h-auto `}
            >
              <div css={tw`col-start-1 col-end-13`}>
                <h2 css={tw`font-light mb-3`}>{department.name}</h2>
                <span>
                  {department.hoursToDisplay.map(hours => {
                    /* Hours of Operation */
                    return (
                      <div css={[tw`text-sm`]}>
                        {hours?.openTime ? (
                          <div
                            css={[
                              tw`grid overflow-hidden grid-cols-12 grid-rows-1 gap-1 grid-flow-row w-auto h-auto`,
                            ]}
                          >
                            <h3
                              css={[
                                tw`text-xs font-semibold col-start-1 col-span-6 md:(ml-3)`,
                              ]}
                            >
                              {hours.day}:
                            </h3>
                            <span css={[tw`mb-1 block col-start-7 col-span-6`]}>
                              {hours.openTime} - {hours.closeTime}
                            </span>
                          </div>
                        ) : (
                          <div
                            css={[
                              tw`grid overflow-hidden grid-cols-12 grid-rows-1 gap-1 grid-flow-row w-auto h-auto mb-6`,
                            ]}
                          >
                            <h3
                              css={[
                                tw`text-xs font-semibold col-start-1 col-span-6 md:(ml-3)`,
                              ]}
                            >
                              {hours.day}
                            </h3>
                            <h3
                              css={[
                                tw`text-xs font-book block col-start-7 col-span-6`,
                              ]}
                            >
                              {_("Closed")}
                            </h3>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
          )
        })
      ) : (
        <div css={[tw`font-light text-xs`]}>{"UNKNOWN"}</div>
      )}
      {/* {dealer?.ServiceDynamoUrl && (
        <>
          <Icon.Clock color="red-400" css={[tw`w-3 mr-1 inline`]} />
          <Link
            to={dealer.ServiceDynamoUrl}
            animated
            animatedThin
            css={[
              tw`text-xs pb-0.5 text-red-400 focus-visible:(border-white border-dashed border outline-none -ml-0.5 -mr-0.5 -mb-0.5) `,
            ]}
            onClick={() =>
              handleTealEvent("schedule_service", {
                url: dealer.ServiceUrl,
              })
            }
          >
            {_("Schedule Service")}
          </Link>
        </>
          )} */}
    </div>
  )
}

export default DealerHours
